'use client'

import { Icon } from '@iconify/react';
import "../styles/components/upozorenjabar.css"
import Link from 'next/link';
import MapLocationToDHMZRegion from '../utils/MapLocationToDHMZRegion';

const UpozorenjaBarAlt = ({ location = null, icons = null }) => {
    const areaCode = MapLocationToDHMZRegion(location);

    const areAllCategoriesEmpty = (data) => {
        for (let category in data) {
          if (Array.isArray(data[category]) && data[category].length > 0) {
            return false;
          }
        }
        return true;
      }

    const mapRegionCode = (code) => {
        switch (code) {
            case 'HR001':
                return 'Knin';
            case 'HR002':
                return 'Zagreb';
            case 'HR003':
                return 'Karlovac';
            case 'HR004':
                return 'Gospić';
            case 'HR005':
                return 'Osijek';
            case 'HR006':
                return 'Rijeka';
            case 'HR007':
                return 'Dubrovnik';
            case 'HR008':
                return 'Split';
            default:
                return 'Hrvatska';
        }

    }

    const locationArrayToString = (locationArray) => {
        if (!locationArray || locationArray.length === 0) {
            return '';
        }

        let locationString = 'regije: ';

        locationArray.forEach((location, index) => {
            if(locationArray.length === 1){
                locationString = mapRegionCode(location) + " i okolinu";
                return;
            }

            if (index > 2) {
                return;
            }

            locationString += mapRegionCode(location);

            if (index === 2) {
                locationString += '...';
                return;
            }

            if (index < locationArray.length - 1 && index < 2) {
                locationString += ', ';
            }
        });

        return locationString;
    }


    return (
        icons && !areAllCategoriesEmpty(icons) && (
            <Link href="/upozorenja" className="text-center flex items-center justify-center bg-[#D40000] transition duration-200 hover:bg-[#e90000] py-2 sm:py-4 border-b-2 border-white cursor-pointer">
                <div className="flex flex-col lg:flex-row items-center">
                    <div className='flex items-center pb-2 lg:pb-0 pr-0 lg:pr-3'>
                        <Icon icon="mingcute:alert-fill" style={{ color: 'rgba(255, 255, 255, 1)', fontSize: '24px' }} />
                        <p className="font-semibold text-[12px] sm:text-lg text-white ml-2">Upozorenja {location ? `za regiju ${mapRegionCode(areaCode)}` : 'za Hrvatsku'}</p>
                        <p className="ml-2 text-[10.5px] sm:text-sm text-white hover:underline">by DHMZ</p>
                    </div>
                    <div className="flex flex-col lg:border-l border-white lg:flex-row items-center lg:pl-3 upozorenjabar mx-2 lg:pt-0">
                        <div className='flex grid grid-cols-1 gap-3'>
                            {icons?.rain?.length > 0 && (
                                <div className='flex justify-center md:justify-start items-center flex-row'>
                                    <Icon
                                        className='text-white mr-1 sm:mr-2 text-[18px] sm:text-[24px]'
                                        icon={"carbon:rain-drop"}
                                    />
                                    <p className='text-[12px] text-left sm:text-base text-white'>Obilne padavine za {locationArrayToString(icons.rain)}</p>
                                </div>
                            )}
                            {icons?.wind?.length > 0 && (
                                <div className='flex justify-center md:justify-start items-center flex-row'>
                                    <Icon
                                        className='text-white mr-1 sm:mr-2 text-[18px] sm:text-[24px]'
                                        icon={"tabler:wind"}
                                    />
                                    <p className='text-[12px] text-left sm:text-base text-white'>Jak vjetar za {locationArrayToString(icons.wind)}</p>
                                </div>
                            )}
                            {icons?.snow?.length > 0 && (
                                <div className='flex justify-center md:justify-start items-center flex-row'>
                                    <Icon
                                        className='text-white mr-1 sm:mr-2 text-[18px] sm:text-[24px]'
                                        icon={"ph:snowflake"}
                                    />
                                    <p className='text-[12px] text-left sm:text-base text-white'>Snijeg/led za {locationArrayToString(icons.snow)}</p>
                                </div>
                            )}
                            {icons?.thunder?.length > 0 && (
                                <div className='flex justify-center md:justify-start items-center flex-row'>
                                    <Icon
                                        className='text-white mr-1 sm:mr-2 text-[18px] sm:text-[24px]'
                                        icon={"mdi:thunder"}
                                    />
                                    <p className='text-[12px] text-left sm:text-base text-white'>Grmljavina za {locationArrayToString(icons.thunder)}</p>
                                </div>
                            )}
                            {icons?.fog?.length > 0 && (
                                <div className='flex justify-center md:justify-start items-center flex-row'>
                                    <Icon
                                        className='text-white mr-1 sm:mr-2 text-[18px] sm:text-[24px]'
                                        icon={"solar:fog-bold"}
                                    />
                                    <p className='text-[12px] text-left sm:text-base text-white'>Magla za {locationArrayToString(icons.fog)}</p>
                                </div>
                            )}
                            {icons?.lowtemp?.length > 0 && (
                                <div className='flex justify-center md:justify-start items-center flex-row'>
                                    <Icon
                                        className='text-white mr-1 sm:mr-2 text-[18px] sm:text-[24px]'
                                        icon={"mingcute:low-temperature-fill"}
                                    />
                                    <p className='text-[12px] text-left sm:text-base text-white'>Niske temperature za {locationArrayToString(icons.hightemp)}</p>
                                </div>
                            )}
                            {icons?.hightemp?.length > 0 && (
                                <div className='flex justify-center md:justify-start items-center flex-row'>
                                    <Icon
                                        className='text-white mr-1 sm:mr-2 text-[18px] sm:text-[24px]'
                                        icon={"mingcute:high-temperature-fill"}
                                    />
                                    <p className='text-[12px] text-left sm:text-base text-white'>Visoke temperature za {locationArrayToString(icons.hightemp)}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Link>
        )
    );
};

export default UpozorenjaBarAlt;
