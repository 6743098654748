import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/(pages)/layout.js\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/AdBanner.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/Cookies/CookieNotice.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/Footer/Footer.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/Header/Header.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/UpozorenjaPopup.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/GoogleAnalytics.js");
